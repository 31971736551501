<template>
  <div class="vraag" :class="vraagAntwoordenClass" v-if="vraag.id">
    <div class="vraag__header" :style="categorieKleurStyle" v-if="showVraag">
      <div class="vraag__header__titel">Fraach {{ vraag.nummer }}</div>
      <div class="vraag__header__categorie">
        <div class="vraag__header__categorie__afbeelding">
          <Image :fileName="vraag.categorie.afbeelding" />
        </div>
        <div class="vraag__header__categorie__titel">
          {{ vraag.categorie.titel }}
        </div>
      </div>
      <div class="vraag__header__pagina">
        {{ vraag.nummer }}/{{ vraag.totaal }}
      </div>
    </div>
    <div
      class="vraag__media"
      v-if="showVraag && showMedia"
      v-html="embeddedMedia"
    ></div>
    <div class="vraag__content" v-if="showVraag && showContent">
      <div class="vraag__content__tekst">
        {{ vraag.titel }}
      </div>
      <div class="vraag__content__afbeelding">
        <BackgroundImage :fileName="vraag.afbeelding" />
      </div>
    </div>
    <div class="vraag__antwoorden" v-if="showVraag && showAntwoorden">
      <Antwoord
        v-for="antwoord in vraag.antwoorden"
        :key="'antwoord-' + antwoord.id"
        :antwoord="antwoord"
        :timelineDurationSeconds="timelineDurationSeconds"
        :timelineProgressSeconds="timelineProgressSeconds"
        :color="vraag.categorie.kleur"
      />
    </div>
    <div class="vraag__resultaatinfo" v-if="showResultaatinfo">
      <div class="resultaatinfo" :style="categorieKleurStyle">
        <div class="resultaatinfo__titel">DE ANTWURDEN!</div>
        <div class="resultaatinfo__afbeelding">
          <Image :fileName="vraag.categorie.afbeelding" />
        </div>
        <div class="resultaatinfo__counter" v-if="showResultaatinfoCounter">
          <TimeCounter
            :timelineDurationSeconds="timelineDurationSeconds"
            :timelineProgressSeconds="timelineProgressSeconds"
          />
        </div>
      </div>
    </div>
    <div class="vraag__footer">
      <Timeline
        v-if="showTimeline"
        :timelineDurationSeconds="timelineDurationSeconds"
        :timelineProgressSeconds="timelineProgressSeconds"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BackgroundImage from '@/components/BackgroundImage.vue'
import Image from '@/components/Image.vue'
import Timeline from '@/components/Timeline'
import Antwoord from '@/components/kwis/Antwoord'
import TimeCounter from '@/components/TimeCounter'

export default {
  props: [],
  components: {
    TimeCounter,
    Timeline,
    BackgroundImage,
    Image,
    Antwoord,
  },
  data() {
    return {
      timelineInterval: null,
      timelineDurationSeconds: 0,
      timelineProgressSeconds: 0,
      showResultaatinfoCounter: false,
    }
  },
  computed: {
    ...mapState(['kwis', 'time']),
    vraag() {
      return this.kwis.vraag
    },
    vraagIdAndStatus() {
      return this.vraag.id + this.vraag.status
    },
    showVraag() {
      return (
        this.kwis.status === 'media' ||
        this.kwis.status === 'vraag' ||
        this.kwis.status === 'antwoord' ||
        this.kwis.status === 'resultaat'
      )
    },
    showResultaatinfo() {
      return this.kwis.status === 'resultaatinfo'
    },
    showMedia() {
      return this.kwis.status === 'media'
    },
    showContent() {
      return this.kwis.status !== 'media'
    },
    showAntwoorden() {
      return this.vraag.antwoorden
    },
    embeddedMedia() {
      if (!this.kwis.vraag.video_url) {
        return '<h2>Fideo net beskikber.</h2>'
      }
      return (
        '<iframe width="986" height="554" src="https://www.youtube.com/embed/' +
        this.kwis.vraag.video_url +
        '?controls=0&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>'
      )
    },
    categorieKleurStyle() {
      return {
        'background-color': '#' + this.vraag.categorie.kleur,
        color: '#ffffff',
      }
    },
    showTimeline() {
      if (!this.timelineDurationSeconds) {
        return false
      }
      return (
        this.vraag.status === 'media' ||
        this.vraag.status === 'vraag' ||
        this.vraag.status === 'antwoord'
      )
    },
    vraagAntwoordenClass() {
      return this.vraag.categorie.class
    },
  },
  methods: {
    getProgressSeconds(durationSeconds, progressSeconds) {
      if (progressSeconds > durationSeconds) {
        return durationSeconds
      }
      if (0 > progressSeconds) {
        return 0
      }
      return progressSeconds
    },
    playSound(fileName) {
      console.log('playSound', fileName)
      let audio = new Audio('/audio/' + fileName)
      audio.play()
    },
  },
  watch: {
    vraagIdAndStatus: {
      immediate: true,
      handler(newVal, oldVal) {
        this.showResultaatinfoCounter = false
        if (!this.vraag.id) return
        if (this.vraag.status === 'media') {
          this.timelineDurationSeconds = this.vraag.mediaSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.mediaSeconden,
            this.time.timestamp - this.vraag.mediaStartAt
          )
        } else if (this.vraag.status === 'vraag') {
          this.timelineDurationSeconds = this.vraag.vraagSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.vraagSeconden,
            this.time.timestamp - this.vraag.vraagStartAt
          )
          this.$store.dispatch('audio/play', {
            id: 'vraag_in_beeld'
          })
        } else if (this.vraag.status === 'antwoord') {
          this.timelineDurationSeconds = this.vraag.antwoordSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.antwoordSeconden,
            this.time.timestamp - this.vraag.antwoordStartAt
          )
          this.$store.dispatch('audio/play', {
            id: 'multiplechoice_in_beeld'
          })
          this.$store.dispatch('audio/play', {
            id: 'timer_30s',
            session: 1,
          })
        } else if (this.vraag.status === 'resultaatinfo') {
          this.timelineDurationSeconds = this.vraag.resultaatinfoSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.resultaatinfoSeconden,
            this.time.timestamp - this.vraag.resultaatinfoStartAt
          )
          this.showResultaatinfoCounter = true
          this.$store.dispatch('audio/play', {
            id: 'aankondiging_antwoorden'
          })
        } else if (this.vraag.status === 'resultaat') {
          this.timelineDurationSeconds = this.vraag.resultaatSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.resultaatSeconden,
            this.time.timestamp - this.vraag.resultaatStartAt
          )
        } else {
          return
        }
        this.$store.dispatch('controls/setShow', false)
        clearInterval(this.timelineInterval)
        this.timelineInterval = setInterval(() => {
          if (this.timelineProgressSeconds < this.timelineDurationSeconds) {
            this.timelineProgressSeconds++
            if (this.showTimeline && this.vraag.status === 'antwoord') {
            }
            if (this.showResultaatinfoCounter) {
            }
          } else {
            if (this.showTimeline && this.vraag.status === 'antwoord') {
              this.$store.dispatch('audio/stop', 1)
            }
            clearInterval(this.timelineInterval)
            if (!this.kwis.autoPlay) {
              this.$store.dispatch('controls/setShow', true)
            }
          }
        }, 1000)
      },
    },
  },
  mounted() {
    if (!this.kwis.id) {
      this.$router.push({ name: 'Home' })
    }
  },
}
</script>

<style lang="scss" scoped>
.vraag {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(1080px - 192px);

  &__header {
    width: 100%;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    font-family: AmsiProCondensed-Black;
    color: #ffffff;
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
    padding: 20px 0 16px;

    &__titel {
      padding: 0 32px;
      flex-grow: 1;
    }

    &__categorie {
      position: relative;
      display: flex;
      flex-shrink: 0;

      &__afbeelding {
        position: absolute;
        margin-top: calc(-20px + -25px);
        height: 128px;

        img {
          max-height: 100%;
        }
      }

      &__titel {
        padding-left: 170px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__pagina {
      padding: 0 32px 0 128px;
    }
  }

  &__media {
    margin-top: 80px;
  }

  &__content {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 33px;
    line-height: 48px;

    &__afbeelding {
      height: 300px;
      width: 300px;
      border-radius: 32px;
      overflow: hidden;
      flex-shrink: 0;
      margin-left: 20px;

      img {
        max-height: 100%;
      }
    }
  }

  &__antwoorden {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
  }

  &__footer {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
  }

  .resultaatinfo {
    position: relative;
    width: 662px;
    height: 662px;
    border-radius: 44px;
    margin: 100px auto 0;

    &__titel {
      text-align: center;
      font-family: AmsiProCondensed-Black;
      font-size: 66px;
      line-height: 66px;
      padding-top: 95px;
    }

    &__afbeelding {
      height: 320px;
      margin-top: 55px;
      text-align: center;

      img {
        height: 100%;
      }
    }

    &__counter {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}
</style>
