const state = {
  websockets: false,
  socketId: null,
  subscribed: false,
}

const mutations = {
  setWebsockets(state, websockets) {
    state.websockets = websockets
  },
  setSocketId(state, socketId) {
    state.socketId = socketId
  },
  setSubscribed(state, subscribed) {
    state.subscribed = subscribed
  },
}

const actions = {
  setWebsockets({ commit }, websockets) {
    commit('setWebsockets', websockets)
  },
  setSocketId({ commit }, socketId) {
    commit('setSocketId', socketId)
  },
  setSubscribed({ commit }, subscribed) {
    commit('setSubscribed', subscribed)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
