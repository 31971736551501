<template>
  <img v-if="fileName" :src="src" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['fileName'],
  computed: {
    ...mapState(['config']),
    src() {
      return this.$store.state.config.urlStorage + '/image/' + this.fileName
    },
  },
}
</script>

<style scoped lang="scss"></style>
