<template>
  <div class="notification-bar" :class="type">
    {{ notification.message }}
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  computed: {
    type() {
      return this.notification.type
    },
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000)
  },
  unmounted() {
    clearTimeout(this.timeout)
  },
  methods: mapActions('notification', ['remove']),
}
</script>

<style scoped lang="scss">
.notification-bar {

  width: 100%;
  padding: 5px 15px;
  text-align: center;
  line-height: 46px;
  font-size: 30px;
}

.success {
  background-color: rgb(139, 199, 81, 0.8);
  color: #ffffff;
}

.error {
  background-color: rgb(255, 0, 0, 0.8);
  color: #ffffff;
}
</style>
