<template>
  <modal ref="modal">
    <div class="confirm-dialog">
      <div class="confirm-dialog__title">
        {{ title }}
      </div>
      <div class="confirm-dialog__text" v-html="message">
      </div>
      <div class="confirm-dialog__controls">
        <div class="bk-btn bk-btn--secondary bk-btn--fa-left" @click="cancel" v-html="cancelText"></div>
        <div class="bk-btn bk-btn--primary bk-btn--fa-left" @click="confirm" v-html="confirmText"></div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'ConfirmDialogue',
  components: { Modal },
  data: () => ({
    title: undefined,
    message: undefined,
    confirmText: undefined,
    cancelText: undefined,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.confirmText = opts.confirmText
      this.cancelText = opts.cancelText
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    confirm() {
      this.$refs.modal.close()
      this.resolvePromise(true)
    },
    cancel() {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
  },
}
</script>

<style lang="scss" scoped>

.confirm-dialog {
  text-align: center;
  padding: 40px;
  background-color: #ffeb94;

  &__title {
    font-family: AmsiProCondensed-Black;
    font-size: 48px;
    }

  &__text {
    padding: 40px 0 0;
  }

  &__controls {
    padding: 40px 0 0;
    display: flex;
    justify-content: space-between;

    .bk-btn {
      margin-left: 0;
    }
  }
}
</style>
