<template>
  <div class="stand" v-if="kwis.id">
    <div class="stand__settings-button">
      <SettingsButton :icon="true" />
    </div>
    <div class="stand__header">
      <img src="/img/logo.png" />
    </div>
    <div class="stand__board bk-shadow" v-if="stand.nieuwe.length">
      <div class="stand__board__titel">TUSKENSTAN</div>
      <Stand @setShowPlaats="setShowPlaats" />
    </div>
    <div class="stand__gelijk-tijd" ref="gelijkTijd">
      <img src="/img/tijd-icon.svg" /> = gemiddelde tiid nedich foar antwurden
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Stand from '@/components/kwis/Stand'
import SettingsButton from '@/components/SettingsButton'

export default {
  props: [],
  components: {
    Stand,
    SettingsButton,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'stand']),
  },
  methods: {
    setShowPlaats(show) {
      setTimeout(() => {
        this.$refs.gelijkTijd.style.opacity = show ? '1' : '0'
      }, 0)

    },
  },
}
</script>

<style lang="scss" scoped>
.stand {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 70px 272px 44px;
  background-color: #ffcc4e;

  &__settings-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  &__header {
    margin: 0 -12px;

    img {
      width: calc(100%);
    }
  }

  &__board {
    position: relative;
    height: 100%;
    background-color: #ffeb94;
    border-radius: 28px;
    margin-top: 30px;

    &__titel {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      font-family: AmsiProCondensed-Black;
      font-size: 30px;
      line-height: 30px;
      padding: 10px 50px 6px;
      border-radius: 28px;
      min-width: 382px;
      text-align: center;
    }
  }

  &__gelijk-tijd {
    margin-top: 24px;
    text-align: center;
    opacity: 0;
  }
}
</style>
