<template>
  <div class="start" v-if="kwis.id">
    <Header :locatie="kwis.locatie" />
    <div class="start__settings-button">
      <SettingsButton :icon="true" />
    </div>
    <div class="blokken">
      <div class="blokken__left">
        <div class="intro-1" v-if="currentIntro === 1">
          <div class="intro-1__title">Wolkom!</div>
          <div class="intro-1__text">
            <p>
              Doch mei oan de leukste Fryske kroechkwis fan 'e wrâld! Test dyn
              kennis fan it Frysk tegearre mei dyn freonen, buorlju, kollega's,
              omkes en muoikes of dyn folsleine sportteam!
            </p>
            <p>
              Wa makket de minste flaters en is de winner fan de Fryske
              Kroechkwis fan <b>{{ kwis.plaats }}</b
              >?
            </p>
            <p><b>In soad wille en sukses!</b></p>
          </div>
        </div>
        <div class="intro-2" v-if="currentIntro === 2">
          <div class="intro-2__title">{{ kwis.countProgrammaVragen }} mearkarfragen<br />yn {{ kwis.countCategories }} omgongen!</div>
          <div class="intro-2__description">
            Dejinge mei de minste flaters en dy’t it rapst<br />de antwurden
            jout, wint de Praat mar Frysk<br />Kroechkwis fan
            {{ kwis.locatie }}!
          </div>
          <img class="intro-2__question-timer" src="/img/question-timer.png" />
          <img class="intro-2__beker" src="/img/beker.png" />
        </div>
        <div class="intro-3" v-if="currentIntro === 3">
          <div class="intro-3__title">Hoe dochst mei?</div>
          <ol class="intro-3__list">
            <li>Sken de QR-koade op it grutte skerm</li>
            <li>Folje dyn namme yn</li>
            <li>Kies in profylfoto</li>
            <li>Wachtsje oant de kwismaster<br />de kwis start!</li>
          </ol>
          <img class="intro-3__arrow" src="/img/arrow.png" />
          <img class="intro-3__beer-and-wine" src="/img/beer-and-wine.png" />
        </div>
      </div>
      <div class="blokken__right">
        <div class="access">
          <div class="access__qr-title">Sken de koade om mei te dwaan:</div>
          <div class="access__qr-code">
            <qrcode-vue :value="kwis.qrCodeUrl" :size="200" level="L" />
          </div>
        </div>
      </div>
    </div>
    <img v-if="currentIntro === 1" class="hand" src="/img/hand.png" />
    <TimelineSmall
      v-if="showTimelineSmall"
      :duration="timelineSmallDuration"
      :progress="timelineSmallProgress"
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TimeBeforeStartCounter from '@/components/TimeBeforeStartCounter'
import { mapState } from 'vuex'
import Header from '@/components/Header'
import SettingsButton from '@/components/SettingsButton'
import TimelineSmall from '@/components/TimelineSmall'

export default {
  props: [],
  components: {
    TimelineSmall,
    Header,
    QrcodeVue,
    TimeBeforeStartCounter,
    SettingsButton,
  },
  data() {
    return {
      showTimelineSmall: true,
      timelineSmallDuration: 1000,
      timelineSmallProgress: 0,
      currentIntro: 1,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    secondsBeforeStart() {
      let startAtTimestamp = this.$timestamp(this.$date(this.kwis.startOp))
      let secondsBeforeStart = startAtTimestamp - this.time.timestamp
      if (secondsBeforeStart < 1) secondsBeforeStart = 0
      return secondsBeforeStart
    },
  },
  methods: {
    showIntro() {
      console.log(Date())
      clearInterval(this.timelineSmallInterval)
      this.timelineSmallInterval = setInterval(() => {
        if (this.timelineSmallProgress < this.timelineSmallDuration) {
          this.timelineSmallProgress++
        } else {
          //console.log(Date())
          if (this.currentIntro < 3) {
            this.currentIntro++
          } else {
            this.currentIntro = 1
          }
          this.timelineSmallProgress = 0
          //clearInterval(this.timelineSmallInterval)
        }
      }, 10)
    },
    playSound(fileName) {
      console.log('playSound', fileName)
      let audio = new Audio('/audio/' + fileName)
      audio.play()
    },
  },
  mounted() {
    this.$store.dispatch('controls/setShow', true)
    this.showIntro()
  },
}
</script>

<style lang="scss" scoped>
.start {
  background-color: #ffeb94;
  height: 100%;
  position: relative;

  &__settings-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.blokken {
  display: flex;
  justify-content: space-between;
  margin: 120px 150px;
  height: 494px;

  &__left {
    width: 820px;
    flex-shrink: 0;
    z-index: 11;

    .intro-1 {
      width: 700px;

      &__title {
        font-family: Chango;
        font-size: 63px;
        line-height: 63px;
        color: #dc0c15;
      }

      &__text {
        font-family: 'Roboto';
        font-weight: 500;
        margin-top: 40px;
        font-size: 33px;
        line-height: 48px;
      }
    }

    .intro-2 {
      position: relative;
      border-radius: 20px;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      padding: 40px 60px 0;

      &__title {
        margin-top: 16px;
        color: #73b5b6;
        font-size: 40px;
        line-height: 52px;
        font-weight: bold;
        text-align: center;
      }

      &__description {
        margin-top: 46px;
        color: #333132;
        font-size: 33px;
        line-height: 48px;
        font-weight: bold;
      }

      &__question-timer {
        position: absolute;
        top: -45px;
        left: 30px;
      }

      &__beker {
        position: absolute;
        bottom: -70px;
        right: 30px;
      }
    }

    .intro-3 {
      position: relative;
      border-radius: 20px;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      padding: 40px 60px 40px;

      &__title {
        font-family: Chango;
        margin-top: 16px;
        color: #dc0c15;
        font-size: 42px;
        line-height: 48px;
      }

      ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        display: flex;
        flex-wrap: wrap;
        margin: 30px 0 0;
        padding: 0;

        li {
          counter-increment: my-awesome-counter;
          display: flex;
          width: 100%;
          color: #333132;
          font-size: 33px;
          line-height: 48px;
          font-weight: bold;
          margin-bottom: 20px;

          &::before {
            content: counter(my-awesome-counter);
            color: #333132;
            font-size: 33px;
            line-height: 48px;
            margin-right: 30px;
            font-family: Chango;
            margin-top: -1px;
          }
        }
      }

      &__arrow {
        position: absolute;
        top: 133px;
        right: -123px;
      }

      &__beer-and-wine {
        position: absolute;
        bottom: -55px;
        right: 30px;
      }
    }
  }

  &__right {
    width: 664px;
    flex-shrink: 0;
  }
}

.hand {
  position: absolute;
  z-index: 10;
  height: 470px;
  bottom: -20px;
  left: 715px;
}

.access {
  position: relative;
  font-family: AmsiProCondensed-Black;
  border-radius: 20px;
  background-color: #ffcc4e;
  width: 100%;
  height: 100%;
  padding: 30px 0 0;
  text-align: center;

  &__qr-title {
    font-family: Roboto;
    font-size: 36px;
    line-height: 100px;
    font-weight: 700;
    color: #dc0c15;
  }

  &__qr-code {
    position: absolute;
    bottom: 205px;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    background-color: #fff;
    width: 300px;
    height: 300px;
    text-align: center;
    padding: 50px;
  }
}
</style>
