<template>
  <span class="bk-link bk-link--fa-right" @click.stop="logout()"><span class="bk-link__title">Utlogge</span><i class="fa-regular fa-arrow-right-from-bracket"></i></span>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmDialogue from '@/components/ConfirmDialogue'
import router from '../../router'

export default {
  props: [],
  components: { ConfirmDialogue },
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    async logout() {
      const confirm = await this.$refs.confirmDialogue.show({
        title: 'Utlogge?',
        message: 'Witst seker datst útlogge wolst',
        confirmText: "<i class=\"fa-regular fa-arrow-right-from-bracket\"></i>Ja, útlogge",
        cancelText: "<i class=\"fa-regular fa-arrow-left\"></i>Nee, werom",
      })
      if (confirm) {
        await router.push({ name: 'KwismasterLogout' })
      }
    },
  },
}
</script>
