<template>
  <div class="uitslag" ref="uitslag">
    <div class="header">
      <div class="header__title">UTSLACH</div>
      <div class="header__pagination">{{ page }} / {{ pages }}</div>
    </div>
    <div class="teams__scroller__container">
      <div class="teams__scroller__fade-out"></div>
      <div class="teams__scroller" ref="teamsScroller">
        <div class="teams__container" ref="teamsContainer">
          <AfgerondTeam
            v-for="team in teams"
            :key="'team-' + team.id"
            :team="team"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AfgerondTeam from './AfgerondTeam'
import { gsap, Linear } from 'gsap'
import { mapState } from 'vuex'

export default {
  components: { AfgerondTeam },
  props: ['teams'],
  data() {
    return {
      page: 1,
      teamsOnPage: 4,
      interval: 6,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    pages() {
      return Math.ceil(this.teams.length / this.teamsOnPage)
    },
  },
  methods: {
    scrollDownAndUp() {
      let tl = gsap.timeline()
      let teamsScrollerEl = this.$refs.teamsScroller
      let teamsContainerEl = this.$refs.teamsContainer
      let scrollTo =
        teamsContainerEl.clientHeight + 36 - teamsScrollerEl.clientHeight
      tl.to(
        teamsScrollerEl,
        {
          duration: 0.8,
          scrollTo: scrollTo,
          repeatDelay: 1.2,
          repeat: -1,
          yoyo: true,
          ease: Linear.easeNone,
        },
        '>' + this.interval.toString()
      )
    },
    animateUitslag() {
      let tl = gsap.timeline({ repeat: 0 })
      tl.to(
        this.$refs.uitslag,
        {
          duration: 1,
          scale: 1,
          ease: 'back.out(1.7)',
        },
        '>10'
      )
      tl.call(
          () => {
            console.log('debug 1')
          },
          this,
          '>-0.9'
      )
      tl.call(
          () => {
            this.scrollDownInStepsAndUp()
          },
          this,
          '>0.9'
      )
    },
    scrollDownInStepsAndUp() {
      let tl = gsap.timeline({ repeat: -1 })
      let teamsScrollerEl = this.$refs.teamsScroller
      let teamsContainerEl = this.$refs.teamsContainer
      if (!teamsScrollerEl || !teamsContainerEl) {
        return
      }
      let scrollTo
      const teamElHeight = 118
      teamsContainerEl.style.paddingBottom =
        (
          (this.teams.length % this.teamsOnPage) * teamElHeight +
          36 +
          20
        ).toString() + 'px'
      for (let page = 1; page < this.pages; page++) {
        scrollTo = this.teamsOnPage * teamElHeight * page
        tl.to(
          teamsScrollerEl,
          {
            duration: 0.6,
            scrollTo: scrollTo,
            ease: Linear.easeNone,
          },
          '>' + this.interval.toString()
        )
        tl.call(
          () => {
            this.page = page + 1
          },
          this,
          '>'
        )
      }
      tl.to(
        teamsScrollerEl,
        {
          duration: 0.6,
          scrollTo: 0,
          ease: Linear.easeNone,
        },
        '>' + this.interval.toString()
      )
      tl.call(
        () => {
          this.page = 1
        },
        this,
        '>'
      )
    },
  },
  mounted() {
    this.animateUitslag()
  },
}
</script>

<style lang="scss" scoped>
.uitslag {
  transform: scale(0);
}
.header {
  display: flex;
  font-family: AmsiProCondensed-Black;
  font-size: 30px;
  line-height: 30px;
  padding: 15px 0 11px 100px;
  border-radius: 28px;
  background-color: #ffcc4e;
  text-align: center;
  margin-left: 5px;

  &__title {
    flex-grow: 1;
  }

  &__pagination {
    width: 100px;
  }
}

.teams {
  &__scroller {
    position: relative;
    width: 100%;
    height: 564px;
    //-ms-overflow-style: none; /* for Internet Explorer, Edge */
    //scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    padding-left: 5px;
    //overflow-x: visible;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    &__container {
      position: relative;
    }

    &__fade-out {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 36px;
      z-index: 1000;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 235, 148, 1),
        rgba(255, 235, 148, 0)
      );
    }
  }

  &__container {
    padding: 36px 0 0;
  }
}
</style>
