<template>
  <div class="schedule__container">
    <button
      class="schedule bk-icon bk-icon--green"
      :class="scheduleClass"
      v-if="show"
      title="folgjende"
      @click.stop="scheduleNext()"
    >
      <i v-if="!this.controls.show || this.schedulePressDelay" class="fa-regular fa-loader fa-spin"></i>
      <i v-else class="fa-regular fa-play"></i>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import KwisService from '@/services/KwisService'

export default {
  data() {
    return {
      schedulePressDelay: false,
    }
  },
  computed: {
    ...mapState(['kwis', 'controls']),
    scheduleClass() {
      if (!this.controls.show || this.schedulePressDelay) {
        return 'bk-icon--busy'
      }
      return ''
    },
    show() {
      if (!this.kwis.id) {
        return false
      }
      if (this.kwis.status === 'afgerond') {
        return false
      }
      return true
    },
  },
  methods: {
    scheduleNext() {
      if (!this.controls.show || this.schedulePressDelay) return
      this.schedulePressDelay = true
      setTimeout(() => {
        this.schedulePressDelay = false
      }, 5000)
      KwisService.scheduleNext()
    },
  },
}
</script>

<style scoped lang="scss">
.schedule {
  font-size: 34px;

  &__container {
    position: absolute;
    bottom: 25px;
    right: 28px;
  }
}
</style>
