<template>
  <div class="settings bk-form">
    <div class="settings__titel">Ynstellingen</div>
    <div class="form-check form-switch mb-4">
      <input
        type="checkbox"
        class="form-check-input"
        :class="{ 'is-invalid': form.settings.fields.autoPlay.error }"
        id="autoPlay"
        v-model="settings.autoPlay"
      />
      <label class="form-check-label" for="autoPlay"
        >Fragen automatysk trochrinne litte</label
      >
    </div>
    <LinkStart />
    <LinkReset />
    <div class="settings__links">
      <span class="bk-link bk-link--fa-right" @click="fullScreen()"><span class="bk-link__title">Folslein skerm</span><i class="fa-regular fa-arrow-up-right-and-arrow-down-left-from-center"></i></span>
    <LinkLogout />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import KwisService from '@/services/KwisService'
import { Modal } from 'bootstrap'
import LinkReset from '@/components/kwis/LinkReset'
import LinkStart from '@/components/kwis/LinkStart'
import LinkLogout from '@/components/kwismaster/LinkLogout'

export default {
  components: { LinkLogout, LinkReset, LinkStart },
  props: [],
  data() {
    return {
      settings: {
        autoPlay: null,
      },
      form: {
        settings: {
          submitting: false,
          fields: {
            autoPlay: { error: false, error_messages: [] },
          },
        },
      },
    }
  },
  watch: {
    settingsAutoPlay: {
      immediate: false,
      handler(newVal, oldVal) {
        if (oldVal === null || newVal === oldVal) {
          return
        }
        this.submit()
      },
    },
  },
  computed: {
    ...mapState(['kwis']),
    settingsAutoPlay() {
      return this.settings.autoPlay
    },
  },
  methods: {
    fullScreen() {
      let elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    hideModal() {
      Modal.getInstance(document.querySelector('#settingsModal')).hide()
    },
    submit() {
      if (this.form.settings.submitting) {
        return
      }
      this.form.settings.submitting = true
      //reset all validation errors
      let validator = this.form.settings.fields
      Object.keys(validator).map(function (objectKey) {
        validator[objectKey].error = false
        validator[objectKey].error_messages = ''
      })
      KwisService.settings(this.settings)
        .then((response) => {
          if (response.data.status == 'ok') {
            //this.hideModal()
            this.$store.dispatch('kwis/set', false)
            if (response.data.message) {
              this.$store.dispatch('notification/add', {
                type: 'success',
                message: response.data.message,
              })
            }
          } else {
            if (response.data.validator) {
              let validator = this.form.settings.fields
              Object.keys(response.data.validator).map(function (objectKey) {
                validator[objectKey].error = true
                validator[objectKey].error_messages =
                  response.data.validator[objectKey]
              })
            }
          }
          this.form.settings.submitting = false
        })
        .catch((error) => {
          this.form.settings.submitting = false
        })
    },
  },
  mounted() {
    this.settings.autoPlay = this.kwis.autoPlay
  },
}
</script>

<style scoped lang="scss">
.settings {
  padding: 50px 60px;

  &__titel {
    font-family: AmsiProCondensed-Black;
    font-size: 40px;
    padding-bottom: 25px;
    color: #dc0c15;
    text-transform: uppercase;
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

}
</style>
