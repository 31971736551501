<template>
  <div
    class="antwoord bk-shadow"
    :class="antwoordClass"
    @click="setAntwoord(vraag.id, antwoord)"
    ref="antwoordRef"
  >
    <div class="antwoord__letter">
      <div ref="antwoordLetterRef">
        {{ antwoord.letter }}
      </div>
    </div>
    <div class="antwoord__titel" ref="antwoordTitelRef">
      {{ antwoord.titel }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['antwoord', 'timelineProgressSeconds', 'timelineDurationSeconds', 'color'],
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    vraag() {
      return this.kwis.vraag
    },
    gegevenAntwoord() {
      return this.vraag.gegevenAntwoord
    },
    canAnswer() {
      if (!this.kwis.isTeam || this.vraag.status !== 'antwoord') {
        return false
      }
      return this.timelineProgressSeconds < this.timelineDurationSeconds
    },
    correctAntwoordKleur() {
      return '#008000'
    },
    antwoordClass() {
      var classes = []
      if (this.vraag.status === 'antwoord') {
        classes.push('cursor')
      }
      if (
        this.vraag.status === 'antwoord' ||
        this.vraag.status === 'resultaat'
      ) {
        if (this.vraag.gegevenAntwoord) {
          if (this.antwoord.id === this.vraag.gegevenAntwoord.id) {
            classes.push('gegeven')
          }
        }
      }
      return classes
    },
    antwoordCorrect() {
      return this.antwoord.correct
    }
  },
  methods: {
    setAntwoord(vraagId, antwoord) {
      if (this.canAnswer) {
        this.$store.dispatch('kwis/setVraagGegevenAntwoord', {
          vraagId,
          antwoord,
        })
      }
    },
    setColors() {
      this.$refs.antwoordRef.style.backgroundColor = '#ffffff';
      this.$refs.antwoordTitelRef.style.color = '#000000';
      this.$refs.antwoordLetterRef.style.backgroundColor = '#' + this.color;
      this.$refs.antwoordLetterRef.style.color = '#ffffff';
    },
    animateAnswer() {
      if (this.vraag.status === 'resultaat') {
        if (this.antwoord.correct) {
          let antwoordCurrentBackgroundColor =
            this.$refs.antwoordRef.style.backgroundColor
          let antwoordLetterCurrentBackgroundColor =
            this.$refs.antwoordLetterRef.style.backgroundColor
          let antwoordLetterCurrentColor =
            this.$refs.antwoordLetterRef.style.color
          let antwoordTitelCurrentColor =
            this.$refs.antwoordTitelRef.style.color
          setTimeout(() => {
            this.$store.dispatch('audio/play', {
              id: 'juiste_antwoord',
            })
          }, 1300)
          setTimeout(() => {
            let timesRun = 0
            let interval = setInterval(() => {
              timesRun += 1
              if (timesRun === 7) {
                clearInterval(interval)
              }
              this.$refs.antwoordRef.style.backgroundColor =
                timesRun % 2
                  ? this.correctAntwoordKleur
                  : antwoordCurrentBackgroundColor
              this.$refs.antwoordLetterRef.style.backgroundColor =
                timesRun % 2 ? '#ffffff' : antwoordLetterCurrentBackgroundColor
              this.$refs.antwoordLetterRef.style.color =
                timesRun % 2
                  ? this.correctAntwoordKleur
                  : antwoordLetterCurrentColor
              this.$refs.antwoordTitelRef.style.color =
                timesRun % 2 ? '#ffffff' : antwoordTitelCurrentColor
            }, 200)
          }, 1500)
        }
      }
    },
  },
  watch: {
    antwoordCorrect: {
      immediate: true,
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.animateAnswer()
        }, 200)
      },
    },
  },
  mounted() {
    this.setColors();
  }
}
</script>
