<template>
  <div
    class="team bk-shadow"
    :class="teamClass"
  >
    <div v-if="bekerSource()" class="team__beker">
      <img :src="bekerSource()" />
    </div>
    <div class="team__plaats">
      <div>{{ team.plaats }}</div>
    </div>
    <div class="team__naam">{{ team.naam }}</div>
    <div class="team__gelijk">
      <GelijkTijd :team="team" />
    </div>
    <div class="team__punten">
      {{ team.punten }}
    </div>
    <div class="team__afbeelding">
      <Image :fileName="imageSource(team.afbeelding)" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image.vue'
import GelijkTijd from "@/components/kwis/GelijkTijd";

export default {
  props: ['team'],
  components: {
    GelijkTijd,
    Image,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'stand']),
    toonStand() {
      return this.kwis.toonStand
    },
    plaatsTonen() {
      if (this.team.plaatsTonen) {
        return true
      }
      return false
    },
    teamClass() {
      return this.team.plaats > 3 ? '' : 'team--podium'
    }
  },
  methods: {
    imageSource(image) {
      if (image) {
        return image
      }
      return 'team-no-image.png'
    },
    bekerSource() {
      if (this.team.plaats === 1) {
        return '/img/beker-klein-goud.png'
      }
      if (this.team.plaats === 2) {
        return '/img/beker-klein-zilver.png'
      }
      if (this.team.plaats === 3) {
        return '/img/beker-klein-brons.png'
      }
      return null

    }
  },
}
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  justify-content: space-between;
  color: #333132;
  font-size: 30px;
  line-height: 30px;
  border-radius: 28px;
  background-color: #fff5c9;
  font-family: AmsiProCondensed-Black;
  text-transform: uppercase;
  position: relative;
  margin: 0 13px 36px 0;

  &--podium {
    background-color: #ffffff;

    .team__plaats div {
      display: none;
    }
  }

  &--toon-punten {
    .team__naam {
      padding-right: 18px;
    }
  }

  &__beker {
    position: absolute;
    left: 5px;
    top: -15px;
    height: 123px;
    width: 99px;

    img {
      max-height: 100%;
    }
  }

  &__plaats {
    margin-left: 30px;
    width: 40px;

    div {
      text-align: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-top: 21px;
      padding-top: 7px;
      color: #50a2a4;
    }
  }

  &__naam {
    flex-grow: 1;
    padding: 28px 0px 24px 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  &__gelijk {
    padding-top: 24px;
    margin-right: 16px;

    &__tijd {
      position: relative;
      font-family: Roboto;
      font-weight: 600;
      font-size: 23px;
      line-height: 23px;
      padding-top: 7px;
      height: 34px;
      background-color: #50a2a4;
      width: 101px;
      border-radius: 17px;
      text-align: right;
      color: #ffffff;
      padding-right: 15px;

      img {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
      }
    }
  }

  &__punten {
    padding: 28px 0 24px 0;
    margin-right: 118px;
    width: 30px;
    text-align: center;
    color: #50a2a4;
  }

  &__afbeelding {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 108px;
    height: 108px;
    border-radius: 50%;
    overflow: hidden;
    border-color: #ffffff;
    border-style: solid;
    border-width: 5px;
    background-color: #ffffff;

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
