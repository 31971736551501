let nextId = 1

export const state = () => ({
  notifications: [],
})

export const mutations = {
  add(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    })
  },
  remove(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToRemove.id
    )
  },
}

export const actions = {
  add({ commit }, notification) {
    if (notification.message) {
      commit('add', notification)
    }
  },
  remove({ commit }, notificationToRemove) {
    commit('remove', notificationToRemove)
  },
}


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
