<template>
  <div class="vraag" v-if="kwis.id">
    <div class="vraag__settings-button">
      <SettingsButton />
    </div>
    <div class="vraag__links">
      <div class="vraag__links__logo">
        <img src="/img/logo-hoog.png" />
      </div>
      <div class="vraag__links__locatie">
        {{ kwis.locatie }}
      </div>
      <div class="vraag__links__teams">
        <Teams />
      </div>
    </div>
    <div class="vraag__rechts">
      <Vraag />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Teams from '@/components/kwis/Teams'
import Vraag from '@/components/kwis/Vraag'
import SettingsButton from "@/components/SettingsButton";

export default {
  components: {
    Teams,
    Vraag,
    SettingsButton,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
}
</script>

<style lang="scss" scoped>
.vraag {
  display: flex;
  height: 100%;

  &__settings-button {
    position: absolute;
    top: 0px;
    left: 541px;
  }

  &__links {
    width: 650px;
    background-color: #ffcc4e;
    height: 100%;
    padding: 45px 60px 0 120px;

    &__logo {
      img {
        width: 100%;
      }
    }

    &__locatie {
      font-size: 30px;
      font-weight: 600;
      line-height: 30px;
      padding: 10px 50px 6px;
      font-family: AmsiProCondensed-Black;
      color: #69b0b0;
      text-transform: uppercase;
      text-align: center;
      background-color: #fff;
      border-radius: 27px;
      margin: 40px 0 20px;
    }

    &__teams {
      margin: 0px -10px 0;
    }
  }

  &__rechts {
    width: 1270px;
    background-color: #ffeb94;
    height: 100%;
    padding: 106px 142px 0 142px;
  }
}
</style>
