<template>
  <button type="submit" class="bk-btn" :class="classButton">
    {{ title }}
    <i v-if="submitting" class="fa-regular fa-loader fa-spin"></i>
  </button>
</template>

<script>
export default {
  props: ['title', 'submitting'],
  computed: {
    classButton() {
      if (this.submitting) {
        return 'bk-btn--submitting'
      }
      return null
    },
  },
}
</script>

<style scoped lang="scss"></style>
