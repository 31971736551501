<template>
  <button
    class="bk-btn bk-btn--secondary bk-btn--full bk-btn--no-shadow bk-btn--fa-left bk-btn--border mb-4"
    @click="start()"
  >
    <i class="fa-regular fa-arrow-left"></i>QR-koade toane
  </button>
</template>

<script>
import { mapState } from 'vuex'
import KwisService from '@/services/KwisService'

export default {
  data() {
    return {
      submitting: false,
    }
  },
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    async start() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      KwisService.start()
        .then(() => {
          this.submitting = false
        })
        .catch((error) => {
          console.log(error)
          this.submitting = false
        })
    },
  },
}
</script>
