<template>
  <Loader v-if="!loaded" />
  <div class="loaded" v-if="loaded">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SanctumService from '@/services/SanctumService'
import Loader from '@/components/Loader'

export default {
  components: { Loader },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'network']),
  },
  created() {
    SanctumService.csrfCookie().then(() => {
      const promiseTime = this.$store.dispatch('time/set')
      const promiseConfig = this.$store.dispatch('config/set')
      const promiseKwis = this.$store.dispatch('kwis/set', true)
      Promise.all([promiseTime, promiseConfig, promiseKwis]).then(() => {
        this.loaded = true
      })
    })

    //socket.io
    // window.ws.connector.socket.on('connect', (data) => {
    //   console.log('ws connected', window.ws.socketId())
    //   this.$store.dispatch('network/setWebsockets', true)
    //   this.$store.dispatch('network/setSocketId', window.ws.socketId())
    // })
    // window.ws.connector.socket.on('disconnect',  (data) => {
    //   console.log('ws disconnected')
    //   this.$store.dispatch('network/setWebsockets', false)
    //   this.$store.dispatch('network/setSocketId', null)
    // })
    // window.ws.connector.socket.on('reconnecting',  (attemptNumber) => {
    //   console.log('ws reconnecting', attemptNumber)
    // })

    //pusher.com and laravel-websockets
    this.ws.connector.pusher.connection.bind('connected', (data) => {
      console.log('ws connected', data.socket_id)
      this.$store.dispatch('network/setWebsockets', true)
      this.$store.dispatch('network/setSocketId', data.socket_id)
    })
    this.ws.connector.pusher.connection.bind('disconnected', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind('connecting', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind('unavailable', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind(
      'state_change',
      function (states) {
        console.log('ws states.current', states.current)
        // if (states.current === 'disconnected') {
        //   if (localStorage.getItem('pusherTransportNonTLS')) {
        //     localStorage.removeItem('pusherTransportNonTLS')
        //     location.href = '/kwismaster/programma'
        //   }
        // }
      }
    )
  },
}
</script>

<style lang="scss"></style>
