const audios = [
  {
    id: 'aankondiging_antwoorden',
    file: '/audio/aankondiging_antwoorden.mp3',
  },
  {
    id: 'intro_tussenklassement',
    file: '/audio/intro_tussenklassement.mp3',
  },
  {
    id: 'intro_winnaar',
    file: '/audio/intro_winnaar.mp3',
  },
  {
    id: 'juiste_antwoord',
    file: '/audio/juiste_antwoord.mp3',
  },
  {
    id: 'multiplechoice_in_beeld',
    file: '/audio/multiplechoice_in_beeld.mp3',
  },
  {
    id: 'programma_item_afgevinkt',
    file: '/audio/programma_item_afgevinkt.mp3',
  },
  {
    id: 'timer_30s',
    file: '/audio/timer_30s.mp3',
  },
  {
    id: 'verschuiven_leaderboard',
    file: '/audio/verschuiven_leaderboard.mp3',
  },
  {
    id: 'vraag_in_beeld',
    file: '/audio/vraag_in_beeld.mp3',
  },
  {
    id: 'winnaar',
    file: '/audio/winnaar.mp3',
  },
]

function getAudio(id) {
  let audio = audios.filter((audio) => audio.id === id)
  return audio.length ? { ...audio[0] } : null
}

function getStateAudio(state, session) {
  let stateAudio = state.playing.filter((audio) => audio.session === session)
  return stateAudio.length ? stateAudio[0] : null
}

function getSession() {
  return Math.random().toString(36).substr(2)
}

export const state = () => ({
  playing: [],
})

export const mutations = {
  play(state, audioToPlay) {
    let stateAudio = getStateAudio(state, audioToPlay.session)
    if (!stateAudio) {
      //console.log('audioToPlay', audioToPlay.session)
      audioToPlay.file = audioToPlay.file + '?' + getSession()
      audioToPlay.player = new Audio(audioToPlay.file)
      audioToPlay.player.addEventListener('canplaythrough', (event) => {
        audioToPlay.player.play()
      })
      audioToPlay.player.addEventListener('ended', (event) => {
        //console.log('audioToPlay onended', audioToPlay.session)
        state.playing = state.playing.filter((playingAudio) => {
          if (playingAudio.session === audioToPlay.session) {
            return false
          }
          return true
        })
      })
      state.playing.push(audioToPlay)
    }
  },
  stop(state, session) {
    state.playing = state.playing.filter((playingAudio) => {
      if (playingAudio.session === session) {
        playingAudio.player.pause()
        playingAudio.player.src = ''
        return false
      }
      return true
    })
  },
}

export const actions = {
  play({ commit }, playAudio) {
    let audioToPlay = getAudio(playAudio.id)
    if (!audioToPlay) return
    audioToPlay.session = playAudio.session ? playAudio.session : getSession()
    console.log('audioToPlay', audioToPlay.id, audioToPlay.session)
    commit('play', audioToPlay)
  },
  stop({ commit }, session) {
    commit('stop', session)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
