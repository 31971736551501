<template>
  <div
    class="team bk-shadow"
    :class="{ 'team--toon-punten': toonStand }"
  >
    <div v-if="toonStand" class="team__plaats" :class="teamPlaatsClass">
      <div>{{ team.plaats }}</div>
    </div>
    <div class="team__naam">{{ team.naam }}</div>
    <div v-if="toonStand" class="team__punten">
      {{ team.punten }}
    </div>
    <div class="team__afbeelding">
      <Image :fileName="imageSource(team.afbeelding)" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image.vue'

export default {
  props: ['team'],
  components: {
    Image,
  },
  computed: {
    ...mapState(['kwis']),
    toonStand() {
      return this.kwis.toonStand
    },
    teamPlaatsClass() {
      return (this.team.plaats < 4) ? 'team__plaats--podium' : ''
    }
  },
  methods: {
    imageSource(image) {
      if (image) {
        return image
      }
      return 'team-no-image.png'
    },
  },
}
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  justify-content: space-between;
  color: #333132;
  font-size: 30px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #fff5c9;
  font-family: AmsiProCondensed-Black;
  text-transform: uppercase;
  position: relative;
  margin: 20px 0 40px;
  width: calc(100% - 14px);

  &--toon-punten {
    .team__naam {
      padding-right: 18px;
    }
  }

  &__plaats {
    margin-left: 23px;
    width: 40px;

    div {
      text-align: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-top: 21px;
      padding-top: 7px;
      background-color: transparent;
      color: #50a2a4;
    }

    &--podium {
      div {
        background-color: #50a2a4;
        color: #ffffff;
      }
    }
  }

  &__naam {
    flex-grow: 1;
    padding: 28px 120px 24px 23px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  &__punten {
    padding: 28px 0 24px 0;
    margin-right: 118px;
    width: 30px;
    text-align: center;
    color: #333132;
  }

  &__afbeelding {
    position: absolute;
    right: -14px;
    top: -14px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    border-color: #ffffff;
    border-style: solid;
    border-width: 6px;
    background-color: #ffffff;

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
