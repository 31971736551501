<template>
  <div class="wrapper bk-kwismaster">
    <div class="scale bk-shadow--large" :style="wrapperStyle">
      <router-view />
      <ControlsBottomRight />
      <SystemTray />
      <NotificationContainer />
    </div>
  </div>
</template>

<script>
import ControlsBottomRight from '@/components/kwis/ControlsBottomRight.vue'
import NotificationContainer from '@/components/notification/NotificationContainer'
import SystemTray from '@/components/SystemTray'

export default {
  components: {ControlsBottomRight, NotificationContainer, SystemTray },
  data() {
    return {
      wrapperHeight: 0,
      wrapperWidth: 0,
      wrapperAspectRatio: 0,
      scaleHeight: 1080,
      scaleWidth: 1920,
    }
  },
  computed: {
    scaleAspectRatio() {
      return this.scaleWidth / this.scaleHeight
    },
    wrapperStyle() {
      let zoom
      if (this.wrapperAspectRatio < this.scaleAspectRatio) {
        zoom = this.wrapperWidth / this.scaleWidth
      } else {
        zoom = this.wrapperHeight / this.scaleHeight
      }
      return {
        width: this.scaleWidth + 'px',
        height: this.scaleHeight + 'px',
        zoom: zoom,
      }
    },
  },
  methods: {
    handleResize() {
      this.wrapperHeight = window.innerHeight
      this.wrapperWidth = window.innerWidth
      this.wrapperAspectRatio = window.innerWidth / window.innerHeight
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #ffeb94;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.scale {
  background-color: #ffeb94;
  align-self: center;
  overflow: hidden;
  position: relative;
}
</style>
