<template>
  <div class="afgerond" v-if="kwis.id">
    <Header :locatie="kwis.locatie"/>
    <div class="afgerond-layout">
      <div v-if="jawis" class="afgerond-layout__left">
        <div class="afgerond-layout__left__title">Jawis!</div>
        <div class="afgerond-layout__left__subtitle">
          De winner fan de Fryske Kroechkwis is...
        </div>
        <div class="afgerond-layout__left__winnaar">
          <AfgerondTeamWinnaar :team="teamWinnaar"/>
        </div>
        <div class="afgerond-layout__left__gelijk-tijd" ref="gelijkTijd">
          <img src="/img/tijd-icon.svg"/> = gemiddelde tiid nedich foar
          antwurden
        </div>
      </div>
      <div class="afgerond-layout__right">
        <div class="afgerond-layout__right__teams">
          <AfgerondTeams :teams="teams"/>
        </div>
      </div>
    </div>
    <Confetti/>
    <div class="afgerond__settings-button">
      <SettingsButton :icon="true"/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {gsap, Linear, Sine} from 'gsap'
import Header from '@/components/Header'
import AfgerondTeamWinnaar from '@/components/kwis/AfgerondTeamWinnaar'
import Confetti from '@/components/Confetti'
import AfgerondTeams from '@/components/kwis/AfgerondTeams'
import SettingsButton from '@/components/SettingsButton'

export default {
  props: [],
  components: {
    AfgerondTeams,
    Confetti,
    AfgerondTeamWinnaar,
    Header,
    SettingsButton,
  },
  data() {
    return {
      jawis: false
    }
  },
  computed: {
    ...mapState(['kwis']),
    teamWinnaar() {
      return this.kwis.teams[0]
    },
    teams() {
      return this.kwis.teams
    },
  },
  mounted() {
    const promiseKwis = this.$store.dispatch('kwis/set', true)
    Promise.all([promiseKwis]).then(() => {
      this.$store.dispatch('audio/play', {
        id: 'intro_winnaar'
      })
      setTimeout(() => {
        this.jawis = true
      }, 400)
      setTimeout(() => {
        let huidigeReverse = this.$addStandData(
            this.teams.map((o) => ({...o}))
        ).reverse()
        let tijdTonen =
            huidigeReverse.find((o) => o.tijdTonen === true) !== undefined
        this.$refs.gelijkTijd.style.opacity = tijdTonen ? '1' : '0'
      }, 13000)
    })
  },
}
</script>

<style lang="scss">
.afgerond {
  background-color: #ffeb94;
  height: 100%;
  position: relative;

  &__settings-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.afgerond-layout {
  display: flex;
  justify-content: space-between;
  margin: 130px 150px;

  &__left {
    width: 725px;
    flex-shrink: 0;

    &__title {
      font-family: Chango;
      font-size: 63px;
      line-height: 63px;
      color: #dc0c15;
    }

    &__subtitle {
      font-family: AmsiProCondensed-Black;
      font-weight: 900;
      margin-top: 40px;
      font-size: 48px;
      line-height: 48px;
    }

    &__winnaar {
      margin-top: 100px;
    }

    &__gelijk-tijd {
      opacity: 0;
      transition: opacity 400ms;
      margin-top: 140px;
      font-size: 24px;
    }
  }

  &__right {
    width: 669px;
    flex-shrink: 0;
  }
}
</style>
