import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + '/api/kwismaster/kwis',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status == 401) {
      router.push({ name: 'KwismasterLogin' })
    } else {
      store.dispatch('notification/add', {
        type: 'error',
        message: error.response.data.message,
      })
    }
    return Promise.reject(error)
  }
)

export default {
  login(credentials) {
    return apiClient.post('/login', credentials)
  },
  logout() {
    return apiClient.post('/logout')
  },
  get() {
    return apiClient.get('')
  },
  play(play) {
    return apiClient.post('/play', { play })
  },
  autoPlay(autoPlay) {
    return apiClient.post('/auto-play', { autoPlay })
  },
  scheduleNext() {
    return apiClient.get('/schedule-next')
  },
  scheduleCurrent() {
    return apiClient.get('/schedule-current')
  },
  reset() {
    return apiClient.post('/reset')
  },
  start() {
    return apiClient.post('/start')
  },
  settings(data) {
    return apiClient.post('/settings', data)
  },
}
