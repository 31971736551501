<template>
  <div class="system-tray">
    <i
      :class="classWebsockets"
      :title="titleWebsockets"
      class="fa-regular fa-router"
    ></i>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['network']),
    classWebsockets() {
      return !this.network.websockets ? ['error', 'blink'] : 'success'
    },
    titleWebsockets() {
      return this.network.websockets
        ? 'Ferbining mei Kroechkwis host is goed.'
        : 'Ferbining mei Kroechkwis host is ferbrutsen!'
    },
  },
}
</script>

<style scoped lang="scss">
.system-tray {
  position: absolute;
  top: 5px;
  left: 10px;

  .error {
    color: #dc0c15;
  }

  .success {
    color: #8bc751;
  }

  .blink {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .hide {
    display: none;
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>
