<template>
  <div class="notification-container">
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationBar from './NotificationBar'
import { mapState } from 'vuex'

export default {
  components: {
    NotificationBar,
  },
  computed: mapState('notification', ['notifications']),
}
</script>

<style scoped lang="scss">
.notification-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 1000;
}
</style>
