<template>
  <div class="confetti" ref="confetti"></div>
</template>

<script>
import { gsap, Linear, Sine } from 'gsap'

export default {
  methods: {
    random(min, max) {
      return min + Math.random() * (max - min)
    },
    animateConfetti() {
      this.$store.dispatch('controls/setShow', true)
      let total = 130
      let confettiEl = this.$refs.confetti
      let w = 1920
      let h = 1080
      let x, y, z, img
      gsap.set(confettiEl, { perspective: 700 })
      gsap.set('img', { xPercent: '-50%', yPercent: '-50%' })
      for (let i = 0; i < total; i++) {
        let confettiShredEl = document.createElement('div')
        x = this.random(0, w)
        y = this.random(-200, -150)
        z = this.random(-200, 200)
        img = Math.round(this.random(1, 9))
        gsap.set(confettiShredEl, {
          attr: { class: 'confetti__shred' },
          backgroundImage: "url('/img/confetti-" + img + ".png')",
          x: x,
          y: y,
          z: z,
        })
        confettiEl.appendChild(confettiShredEl)
        gsap.to(confettiShredEl, {
          duration: this.random(4, 10),
          y: h,
          ease: Linear.easeNone,
          repeat: -1,
          delay: -15,
        })
        gsap.to(confettiShredEl, {
          duration: this.random(4, 8),
          x: '+=100',
          rotationZ: this.random(0, 180),
          repeat: -1,
          yoyo: true,
          ease: Sine.easeInOut,
        })
        gsap.to(confettiShredEl, {
          duration: this.random(2, 8),
          rotationX: this.random(0, 360),
          rotationY: this.random(0, 360),
          repeat: -1,
          yoyo: true,
          ease: Sine.easeInOut,
          delay: -5,
        })
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateConfetti()
    }, 6000)
  },
}
</script>

<style lang="scss">
.confetti {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__shred {
    width: 44px;
    height: 35px;
    position: absolute;
    background-size: 100% 100%;
  }
}
</style>
