import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import KwismasterLogin from '@/views/kwismaster/Login.vue'
import KwismasterLogout from '@/views/kwismaster/Logout.vue'
import KwismasterStart from '@/views/kwismaster/Start.vue'
import KwismasterProgramma from '@/views/kwismaster/Programma.vue'
import KwismasterPauze from '@/views/kwismaster/Pauze.vue'
import KwismasterMedia from '@/views/kwismaster/Media.vue'
import KwismasterVraag from '@/views/kwismaster/Vraag.vue'
import KwismasterAfgerond from '@/views/kwismaster/Afgerond.vue'
import KwismasterStand from '@/views/kwismaster/Stand.vue'
import LayoutKwismaster from '@/layouts/Kwismaster.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/kwismaster/ynlogge',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterLogin',
        path: '',
        component: KwismasterLogin,
      },
    ],
  },
  {
    path: '/kwismaster/utlogge',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterLogout',
        path: '',
        component: KwismasterLogout,
      },
    ],
  },
  {
    path: '/kwismaster/start',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterStart',
        path: '',
        component: KwismasterStart,
      },
    ],
  },
  {
    path: '/kwismaster/programma',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterProgramma',
        path: '',
        component: KwismasterProgramma,
      },
    ],
  },
  {
    path: '/kwismaster/pauze',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterPauze',
        path: '',
        component: KwismasterPauze,
      },
    ],
  },
  {
    path: '/kwismaster/media',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterMedia',
        path: '',
        component: KwismasterMedia,
      },
    ],
  },
  {
    path: '/kwismaster/vraag',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterVraag',
        path: '',
        component: KwismasterVraag,
      },
    ],
  },
  {
    path: '/kwismaster/afgerond',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterAfgerond',
        path: '',
        component: KwismasterAfgerond,
      },
    ],
  },
  {
    path: '/kwismaster/stand',
    component: LayoutKwismaster,
    children: [
      {
        name: 'KwismasterStand',
        path: '',
        component: KwismasterStand,
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  let elements = document.querySelector('.modal-backdrop')
  if (elements) {
    elements.remove()
  }
  next()
})

export default router
