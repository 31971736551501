import KwisService from '@/services/KwisService'
import router from '../../router'
import BroadcastLogService from '../../services/BroadcastLogService'

const state = () => ({
    id: null,
    status: null,
    plaats: null,
    locatie: null,
    startOp: null,
    url: null,
    qrCodeUrl: null,
    teams: [],
    toonStand: false,
    countCategories: 0,
    countProgrammaVragen: 0,
    programma: [],
    volgendeProgramma: null,
    vraag: {id: null, status: null, seconden: null, gegevenAntwoord: null},
    play: false,
    autoPlay: false,
    kwismasterToken: null,
})

const mutations = {
    set(state, kwis) {
        state.id = kwis.id
        state.status = kwis.status
        state.plaats = kwis.plaats
        state.locatie = kwis.locatie
        state.startOp = kwis.startOp
        state.url = kwis.url
        state.qrCodeUrl = kwis.qrCodeUrl
        state.teams = kwis.teams.length ? addStandData(kwis.teams) : []
        state.toonStand = kwis.toonStand
        state.countCategories = kwis.countCategories
        state.countProgrammaVragen = kwis.countProgrammaVragen
        state.programma = kwis.programma
        state.volgendeProgramma = kwis.volgendeProgramma
        state.play = !!kwis.play
        state.autoPlay = !!kwis.autoPlay
        state.kwismasterToken = kwis.kwismasterToken
    },
    clear(state) {
        state.id = null
    },
    setVraag(state, vraag) {
        state.vraag = vraag
    },
    setVraagGegevenAntwoord(state, gegevenAntwoord) {
        state.vraag.gegevenAntwoord = gegevenAntwoord
    },
    setPlay(state, play) {
        state.play = play
    },
    setAutoPlay(state, autoPlay) {
        state.autoPlay = autoPlay
    },
}

const actions = {
    set({commit, dispatch, rootState}, redirect) {
        return KwisService.get()
            .then((response) => {
                if (response.data.status == 'ok') {
                    //vuex
                    commit('set', response.data.kwis)
                    //websockets
                    if (!rootState.network.subscribed && rootState.kwis.kwismasterToken) {
                        dispatch('network/setSubscribed', true, {
                            root: true,
                        })
                        setTimeout(() => {
                            this.ws
                                .private('Kwismaster.' + response.data.kwis.id)
                                .listen('.kwis', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('set', wsData.kwis)
                                })
                                .listen('.start', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    this.router.push({name: 'KwismasterStart'})
                                })
                                .listen('.programma', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('set', wsData.kwis)
                                    this.router.push({name: 'KwismasterProgramma'})
                                })
                                .listen('.pauze', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    this.router.push({name: 'KwismasterPauze'})
                                })
                                .listen('.media', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('setVraag', wsData.vraag)
                                    this.router.push({name: 'KwismasterMedia'})
                                })
                                .listen('.vraag', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('setVraag', wsData.vraag)
                                    this.router.push({name: 'KwismasterVraag'})
                                })
                                .listen('.antwoord', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('setVraag', wsData.vraag)
                                    this.router.push({name: 'KwismasterVraag'})
                                })
                                .listen('.resultaatinfo', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('setVraag', wsData.vraag)
                                    this.router.push({name: 'KwismasterVraag'})
                                })
                                .listen('.resultaat', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    commit('setVraag', wsData.vraag)
                                    this.router.push({name: 'KwismasterVraag'})
                                })
                                .listen('.stand', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    dispatch('stand/set', wsData, {
                                        root: true,
                                    })
                                    this.router.push({name: 'KwismasterStand'})
                                })
                                .listen('.afgerond', (wsData) => {
                                    BroadcastLogService.confirm(
                                        wsData.broadcastToken,
                                        rootState.network.socketId
                                    )
                                    this.router.push({name: 'KwismasterAfgerond'})
                                })
                        }, 1000)
                    }
                    //redirect
                    if (redirect) {
                        if (response.data.kwis.status === 'start') {
                            router.push({name: 'KwismasterStart'})
                        } else if (response.data.kwis.status === 'programma') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'pauze') {
                            router.push({name: 'KwismasterPauze'})
                        } else if (response.data.kwis.status === 'media') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'vraag') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'antwoord') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'resultaatinfo') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'resultaat') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'stand') {
                            router.push({name: 'KwismasterProgramma'})
                        } else if (response.data.kwis.status === 'afgerond') {
                            router.push({name: 'KwismasterAfgerond'})
                        } else {
                            router.push({name: 'KwismasterLogin'})
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    },
    clear({commit, dispatch, rootState}) {
        if (rootState.kwis.id) {
            this.ws.leave('Kwismaster.' + rootState.kwis.id);
        }
        dispatch('network/setSubscribed', false, {
            root: true,
        })
        commit('clear')
    },
    reset({commit, dispatch}) {
        // commit('clear')
        return KwisService.reset()
            .then((response) => {
                if (response.data.message) {
                    dispatch(
                        'notification/add',
                        {
                            type: 'success',
                            message: response.data.message,
                        },
                        {
                            root: true,
                        }
                    )
                }
                dispatch('set')
            })
            .catch((error) => {
                console.log(error)
            })
    },
    setVraagGegevenAntwoord({commit, state}, data) {
        let currentGegevenAntwoord = state.vraag.gegevenAntwoord
        commit('setVraagGegevenAntwoord', data.antwoord)
        KwisService.antwoord({
            programma_vraag_id: data.vraagId,
            antwoord_id: data.antwoord.id,
        }).then((response) => {
            if (response.data.status == 'error') {
                commit('setVraagGegevenAntwoord', currentGegevenAntwoord)
            }
        })
    },
    setPlay({commit}, play) {
        return KwisService.play(play).then((response) => {
            if (response.data.status == 'ok') {
                commit('setPlay', play)
            }
        })
    },
    setAutoPlay({commit}, autoPlay) {
        return KwisService.autoPlay(autoPlay).then((response) => {
            if (response.data.status == 'ok') {
                commit('setAutoPlay', autoPlay)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}

function addStandData(teams) {
    teams.sort(function (a, b) {
        if (a.punten === b.punten) {
            if (a.seconden_antwoorden === b.seconden_antwoorden) {
                return ('' + a.naam).localeCompare(b.naam)
            }
            return a.seconden_antwoorden < b.seconden_antwoorden ? -1 : 1
        }
        return a.punten > b.punten ? -1 : 1
    })
    let plaats = 0
    let punten = null
    let seconden_antwoorden = null
    return teams.map((current, index, all) => {
        let tijdTonen = false
        if (index > 0) {
            if (all[index - 1].punten === current.punten) {
                tijdTonen = true
            }
        }
        if (index + 1 < all.length) {
            if (all[index + 1].punten === current.punten) {
                tijdTonen = true
            }
        }
        current.tijdTonen = tijdTonen
        if (current.punten !== punten) {
            punten = current.punten
            seconden_antwoorden = current.seconden_antwoorden
            plaats++
            current.plaatsTonen = true
        } else {
            if (current.seconden_antwoorden !== seconden_antwoorden) {
                seconden_antwoorden = current.seconden_antwoorden
                plaats++
                current.plaatsTonen = true
            } else {
                current.plaatsTonen = false
            }
        }
        current.plaats = plaats
        current.index = index
        // current.top = index * 120
        // current.left = 0
        return current
    })
}
