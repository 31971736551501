import ConfigService from '@/services/ConfigService'

const state = {
  loaded: false,
  urlFrontend: null,
  urlStorage: null,
}

const mutations = {
  set(state, config) {
    state.loaded = true
    state.urlFrontend = config.urlFrontend
    state.urlStorage = config.urlStorage
  },
}

const actions = {
  set({ commit }) {
    return ConfigService.get()
      .then((response) => {
        if (response.data.status == 'ok') {
          commit('set', response.data.config)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
