<template>
  <header>
    <svg
      class="background-header"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1967 697"
      preserveAspectRatio="none"
    >
      <path
        fill-rule="evenodd"
        fill="rgb(255, 204, 78)"
        d="M1966.436,696.230 C1966.436,696.230 1465.945,622.137 983.600,622.137 C501.254,622.137 0.764,696.230 0.764,696.230 L0.764,363.571 L0.764,0.670 L983.600,0.670 L1966.436,0.670 L1966.436,363.571 L1966.436,696.230 Z"
      />
    </svg>
    <img class="logo" src="/img/logo.png" />
    <router-link to="/kwismaster/start" tag="div" class="locatie bk-shadow--large">{{ locatie ? locatie : 'KROECHKWIS' }}</router-link>
  </header>
</template>

<script>
export default {
  props: ['locatie'],
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  height: 330px;
  background-color: #ffcc4e;
  margin-bottom: 90px;
}

.logo {
  position: absolute;
  width: calc(100% - 280px + 20px);
  left: 50%;
  transform: translateX(-50%);
  margin-top: 60px;
}

.background-header {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 330px;
  background-color: #ffeb94;
}

.locatie {
  position: absolute;
  left: 50%;
  bottom: -38px;
  transform: translate(-50%, 0);
  padding: 31px 55px 27px;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  font-family: AmsiProCondensed-Black;
  color: #69b0b0;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
}
</style>
