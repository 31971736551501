<template>
  <div class="bk-form">
    <div class="bk-form__titel">Kwismaster ynlogge</div>
    <form @submit.prevent="submit()">
      <div>
        <label for="email" class="form-label">Email-adres</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': form.login.fields.email.error }"
          id="email"
          v-model="login.email"
        />
        <FormFieldMessages
          :messages="form.login.fields.email.error_messages"
        />
      </div>
      <div>
        <label for="password" class="form-label">Wachtwurd</label>
        <input
          type="password"
          class="form-control"
          :class="{ 'is-invalid': form.login.fields.password.error }"
          id="password"
          v-model="login.password"
        />
        <FormFieldMessages
          :messages="form.login.fields.password.error_messages"
        />
      </div>
      <div class="bk-form__actions">
        <FormSubmitButton title="Ynlogge" :submitting="form.login.submitting"/>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import KwisService from '@/services/KwisService'
import FormFieldMessages from '@/components/FormFieldMessages'
import FormSubmitButton from "@/components/FormSubmitButton";

export default {
  components: { FormFieldMessages, FormSubmitButton},
  props: [],
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      form: {
        login: {
          submitting: false,
          fields: {
            email: { error: false, error_messages: [] },
            password: { error: false, error_messages: [] },
          },
        },
      },
    }
  },
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    submit() {
      this.form.login.submitting = true
      //reset all validation errors
      let validator = this.form.login.fields
      Object.keys(validator).map(function (objectKey) {
        validator[objectKey].error = false
        validator[objectKey].error_messages = ''
      })
      KwisService.login(this.login)
        .then((response) => {
          if (response.data.status == 'ok') {
            this.$store.dispatch('kwis/set', true)
            if (response.data.message) {
              this.$store.dispatch('notification/add', {
                type: 'success',
                message: response.data.message,
              })
            }
          } else {
            if (response.data.validator) {
              let validator = this.form.login.fields
              Object.keys(response.data.validator).map(function (objectKey) {
                validator[objectKey].error = true
                validator[objectKey].error_messages =
                  response.data.validator[objectKey]
              })
            }
          }
          this.form.login.submitting = false
        })
        .catch((error) => {
          this.form.login.submitting = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
