<template>
  <div class="programma-layout" v-if="kwis">
    <div class="programma-layout__links">
      <div class="programma-layout__links__settings-button">
        <SettingsButton/>
      </div>
      <div class="programma-layout__links__logo">
        <img src="/img/logo-hoog.png"/>
      </div>
      <div class="programma-layout__links__titel">
        Dielnimmers:
      </div>
      <div class="programma-layout__links__teams">
        <Teams/>
      </div>
    </div>
    <div class="programma-layout__rechts">
      <div class="pauze">
        <div class="pauze__titel">Skoft</div>
        <div class="pauze__subtitel">Wy gean aanst wer fierder...</div>
        <div class="pauze__afbeelding">
          <div>
            <img src="/img/hourglass.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Teams from '@/components/kwis/Teams'
import Programma from '@/components/kwis/Programma'
import SettingsButton from "@/components/SettingsButton";
import Image from '@/components/Image.vue'

export default {
  props: [],
  components: {
    SettingsButton,
    Teams,
    Programma,
    Image,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'stand']),
  },
}
</script>

<style lang="scss" scoped>
body {
  background-color: #ffeb94;
}

.programma-layout {
  display: flex;
  height: 100%;

  &__links {
    position: relative;
    width: 650px;
    background-color: #ffcc4e;
    height: 100%;
    padding: 45px 60px 0 120px;

    &__settings-button {
      position: absolute;
      top: 0px;
      left: 541px;
    }

    &__logo {
      img {
        width: 100%;
      }
    }

    &__titel {
      font-size: 26px;
      line-height: 26px;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: #333132;
      text-align: center;
      text-transform: uppercase;
      margin: 52px 0 20px;
    }

    &__teams {
      margin: 30px -10px;
    }

    &__stand {
      margin: 40px 0;
    }
  }

  &__rechts {
    width: 1270px;
    background-color: #ffeb94;
    height: 100%;
    padding: 330px 270px 0 192px;

  }
}

.pauze {
  position: relative;
  background-color: #ffffff;
  width: 662px;
  border-radius: 44px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 150px;

  &__titel {
    font-family: Chango;
    font-size: 53px;
    line-height: 53px;
    color: #dc0c15;
    padding-top: 90px;
  }

  &__subtitel {
    font-family: 'Roboto';
    font-weight: 500;
    margin-top: 30px;
    font-size: 33px;
    line-height: 48px;
  }

  &__afbeelding {
    background-color: #ffcc4e;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    border: 7px solid white;
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translate(-50%, 0);

    div {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
