<template>
  <div class="background-image" v-if="fileName" :style="style" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['fileName'],
  computed: {
    ...mapState(['config']),
    src() {
      return this.$store.state.config.urlStorage + '/image/' + this.fileName
    },
    style() {
      return { 'background-image': 'url(' + this.src + ')' }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
