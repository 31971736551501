<template>
  <div class="gelijk-tijd" v-if="team.seconden_antwoorden">
    <img src="/img/team-tijd-icon.svg" />{{
      team.seconden_antwoorden.toFixed(1)
    }}
  </div>
</template>

<script>
export default {
  props: ['team'],
}
</script>

<style lang="scss" scoped>
.gelijk-tijd {
  position: relative;
  font-family: Roboto;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;
  padding-top: 7px;
  height: 34px;
  background-color: #50a2a4;
  width: 101px;
  border-radius: 17px;
  text-align: right;
  color: #ffffff;
  padding-right: 15px;

  img {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
}
</style>
