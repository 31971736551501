<template>
  <div class="timeline">
    <div class="timeline__progress" :style="timelineProgressStyle"></div>
  </div>
</template>

<script>
export default {
  props: ['duration', 'progress'],
  computed: {
    timelineProgressStyle() {
      let percentage =
        (100 / this.duration) * this.progress
      return { width: percentage.toString() + '%' }
    },
  },
}
</script>

<style scoped lang="scss">
.timeline {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 9px;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;

  &__progress {
    height: 100%;
    background-color: #ffcc4e;
    width: 0;
  }
}
</style>
