let timeout

const state = {
  show: true,
}

const mutations = {
  setShow(state, show) {
    state.show = show
  },
}

const actions = {
  setShow({ commit }, show) {
    commit('setShow', show)
  },
  setTimeout({ commit }, seconds) {
    commit('setShow', false)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      commit('setShow', true)
    }, seconds * 1000)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
