<template>
  <button class="bk-btn bk-btn--full bk-btn--fa-left bk-btn--border mb-4" @click="reset()"><i class="fa-regular fa-arrow-rotate-left"></i>Kwis op 'e nij begjinne</button>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmDialogue from '@/components/ConfirmDialogue'
import FormSubmitButton from "../FormSubmitButton";

export default {
  components: {FormSubmitButton, ConfirmDialogue },
  data() {
    return {
      submitting: false,
    }
  },
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    async reset() {
      if (this.submitting) {
        return
      }
      const confirm = await this.$refs.confirmDialogue.show({
        title: "kwis op 'e nij begjinne?",
        message:
          'Ast de kwis op \'e nij begjinst wurde alle antwurden ferwidere en begjint de kwis fan foaren ôf oan.<br>De teams bliuwe wol yn de kwis stean.',
        confirmText: "<i class=\"fa-regular fa-arrow-rotate-left\"></i>Ja, op 'e nij begjinne",
        cancelText: "<i class=\"fa-regular fa-arrow-left\"></i>Nee, werom",
      })
      if (confirm) {
        this.submitting = true
        this.$store.dispatch('kwis/reset').then(() => {
          this.submitting = false
        })
      }
    },
  },
}
</script>
