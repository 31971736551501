<template>
  <div class="team bk-shadow" ref="team">
    <div class="team__beker" ref="teamBeker">
      <img src="/img/beker-goud.png" />
    </div>
    <div class="team__naam">{{ team.naam }}</div>
    <div class="team__afbeelding">
      <Image :fileName="imageSource(team.afbeelding)" />
    </div>
    <div v-if="false" class="team__gelijk__tijd">
      <i class="fa-light fa-timer"></i>{{ team.seconden_antwoorden.toFixed(1) }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image.vue'
import { gsap, Linear, Bounce } from 'gsap'

export default {
  props: ['team'],
  components: {
    Image,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'stand']),
    toonStand() {
      return this.kwis.toonStand
    },
  },
  methods: {
    imageSource(image) {
      if (image) {
        return image
      }
      return 'team-no-image.png'
    },
    animateTeam() {
      let tl = gsap.timeline({ repeat: 0 })
      tl.to(
        this.$refs.team,
        {
          duration: 1,
          scale: 1,
          ease: 'back.out(1.7)',
        },
        '>5'
      )
      tl.call(
        () => {
          console.log('winnaar')
          this.$store.dispatch('audio/play', {
            id: 'winnaar'
          })
        },
        this,
        '>-0.9'
      )
    },
  },
  mounted() {
    this.animateTeam()
  },
}
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  justify-content: space-between;
  color: #333132;
  font-size: 42px;
  line-height: 42px;
  border-radius: 28px;
  background-color: #ffffff;
  font-family: AmsiProCondensed-Black;
  text-transform: uppercase;
  position: relative;
  margin: 9px 9px 36px 0;
  transform: scale(0);

  &__beker {
    position: absolute;
    left: 25px;
    top: -54px;
    width: 179px;
    height: 232px;

    img {
      max-width: 100%;
    }
  }

  &__naam {
    flex-grow: 1;
    padding: 43px 0px 39px 205px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  &__afbeelding {
    position: absolute;
    right: -35px;
    top: -35px;
    width: 194px;
    height: 194px;
    border-radius: 50%;
    overflow: hidden;
    border-color: #ffffff;
    border-style: solid;
    border-width: 10px;
    background-color: #ffffff;

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }

  &__gelijk__tijd {
    position: absolute;
    left: 205px;
    bottom: 0;
    transform: translateY(50%);
    font-family: Roboto;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    padding-top: 5px;
    height: 42px;
    background-color: #50a2a4;
    width: 125px;
    border-radius: 21px;
    text-align: right;
    color: #ffffff;
    padding-right: 20px;

    i {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
}
</style>
