<template>
  <div class="timeline">
    <div class="timeline__progress" :style="timelineProgressStyle"></div>
  </div>
</template>

<script>
export default {
  props: ['timelineDurationSeconds', 'timelineProgressSeconds'],
  computed: {
    timelineProgressStyle() {
      let percentage =
        (100 / this.timelineDurationSeconds) * this.timelineProgressSeconds
      return { width: percentage.toString() + '%' }
    },
  },
}
</script>

<style scoped lang="scss">
.timeline {
  height: 24px;
  background-color: #fff5c9;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 65px;

  &__progress {
    height: 100%;
    background-color: #ffcc4e;
    width: 0;
  }
}
</style>
