<template>
  <div>
    Kwismaster?
    <router-link :to="{ name: 'KwismasterLogin' }">hjir ynlogge</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '../router'

export default {
  name: 'Home',
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  mounted() {
    router.push({ name: 'KwismasterLogin' })
  },
}
</script>
