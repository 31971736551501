<template>
  <div class="programma programma--titel"><div class="programma__naam">Programma:</div></div>
  <div class="programma__scroller" ref="programmaScroller">
    <div class="programma__container" ref="programmaContainer">
      <div
        class="programma"
        :class="programmaClass(programma)"
        :style="programmaStyle(programma)"
        v-for="programma in kwis.programma"
        :key="'programma-' + programma.id"
      >
        <div
          class="programma__afbeelding"
          v-if="programma.categorie.afbeelding"
        >
          <Image :fileName="programma.categorie.afbeelding" />
        </div>
        <div class="programma__naam">
          {{ programma.categorie.naam }}
        </div>
        <div class="programma__afgerond bk-shadow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/components/Image.vue'
import { mapState } from 'vuex'
import gsap, { Linear } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default {
  props: [],
  components: {
    Image,
  },
  data() {
    return {
      setTimeoutScrollDownAndUp: null,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    volgendeProgramma() {
      return this.kwis.volgendeProgramma
    },
  },
  methods: {
    programmaClass(programma) {
      return {
        'programma--categorie': !programma.categorie.pauze,
        'programma--pauze': programma.categorie.pauze,
        'programma--afgerond': programma.finished,
      }
    },
    programmaStyle(programma) {
      return {
        'background-color': '#' + programma.categorie.kleur,
      }
    },
    scrollDownAndUp() {
      let tl = gsap.timeline()
      this.setTimeoutScrollDownAndUp = setTimeout(() => {
        let programmaScroller = this.$refs.programmaScroller
        let programmaContainer = this.$refs.programmaContainer
        let scrollTo =
          programmaContainer.clientHeight - programmaScroller.clientHeight + 44
        tl.to(
          this.$refs.programmaScroller,
          {
            duration: 8,
            scrollTo: scrollTo,
            repeatDelay: 3,
            repeat: -1,
            yoyo: true,
            ease: Linear.easeNone,
          },
          '>'
        )
      }, 5000)
    },
  },
  mounted() {
    this.$store.dispatch('controls/setShow', true)
    if (!this.kwis.id) {
      this.$router.push({ name: 'Home' })
    }
    this.scrollDownAndUp()
  },
  beforeUnmount() {
    if (this.setTimeoutScrollDownAndUp) {
      clearTimeout(this.setTimeoutScrollDownAndUp)
    }
  },
}
</script>

<style lang="scss" scoped>
.programma {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 27px;
  margin-bottom: 44px;
  font-family: AmsiProCondensed-Black;
  font-size: 48px;
  line-height: 48px;

  &__scroller {
    margin-top: -20px;
    width: 100%;
    height: 908px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  &__container {
    padding: calc(20px + 43px) 0 0;
  }

  &--categorie {
    color: #ffffff;
    font-size: 60px;
    line-height: 60px;
    padding: 32px 50px 28px;
    text-transform: uppercase;

    .programma__naam {
      margin-left: 190px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  &--pauze {
    padding: 21px 50px 17px;
  }

  &--titel {
    padding: 21px 50px 17px;
    margin-bottom: 0;
    z-index: 1;
  }

  &--afgerond {
    .programma__afgerond {
      opacity: 1;
    }
  }

  &__naam {
    flex-grow: 1;
    text-align: center;
    margin-left: 0;
    text-transform: uppercase;
  }

  &__afgerond {
    opacity: 0;
    position: absolute;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    background-image: url('/img/afgerond.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: opacity 400ms;

    &:after {
      content: ' ';
    }
  }

  &__afbeelding {
    position: absolute;
    top: -20px;
    left: 30px;
    height: 160px;
    text-align: center;

    img {
      height: 100%;
    }
  }
}
</style>
