<template>
  <Header :locatie="kwis.locatie" />
  <div class="login-layout">
    <KwismasterFormLogin />
  </div>
</template>

<script>
import KwismasterFormLogin from '@/components/kwismaster/FormLogin'
import { mapState } from 'vuex'
import Header from '@/components/Header'

export default {
  props: [],
  components: {
    KwismasterFormLogin,
    Header,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
}
</script>

<style lang="scss">
.login-layout {
  width: 500px;
  margin: 0 auto 0;
}
</style>
