const state = {
  show: false,
  programmaId: null,
  huidige: [],
  nieuwe: [],
}

const mutations = {
  set(state, stand) {
    state.show = stand.show
    state.programmaId = stand.programmaId
    state.huidige = stand.huidige
    state.nieuwe = stand.nieuwe
  },
}

const actions = {
  set({ commit }, stand) {
    commit('set', stand)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
