<template>
  <div class="teams" :class="teamsClass">
    <div class="teams__container" v-if="teams">
      <div
        class="team bk-shadow"
        v-for="team in huidige"
        :key="'team-' + team.kwis_team_id"
        :ref="'team' + team.kwis_team_id"
        :class="teamClass(team)"
      >
        <div class="team__plaats">
          <div class="team__plaats__icon">
            {{ team.plaats }}
          </div>
        </div>
        <div class="team__naam">{{ team.naam }}</div>
        <div class="team__gelijk" v-if="team.tijdTonen">
          <GelijkTijd :team="team" />
        </div>
        <div class="team__punten">
          {{ team.punten }}
        </div>
        <div class="team__afbeelding">
          <Image :fileName="imageSource(team.afbeelding)" />
        </div>
      </div>
    </div>
    <div class="teams__fade__linear"></div>
    <div class="teams__fade__even"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gsap from 'gsap'
import Image from '@/components/Image.vue'
import GelijkTijd from '@/components/kwis/GelijkTijd'

export default {
  components: {
    GelijkTijd,
    Image,
  },
  data() {
    return {
      isAnimating: false,
      showPlaats: false,
      teams: [],
      huidige: [],
      tijdelijke: [],
      nieuwe: [],
      max: 10,
      teamWidth: 566,
      teamHeight: 82,
      teamGutterWidth: 47,
      teamGutterHeight: 36,
      teamRows: 5,
    }
  },
  computed: {
    ...mapState(['stand']),
    programmaId() {
      return this.stand.programmaId
    },
    teamsClass() {
      return this.showPlaats ? 'teams--show-plaats' : ''
    },
  },
  methods: {
    animate() {
      console.log('animate')
      if (this.isAnimating) {
        return
      }
      this.isAnimating = true
      this.showPlaats = false
      this.$store.dispatch('controls/setShow', false)
      let huidigeReverse = this.$addStandData(
        this.stand.nieuwe.slice(0, 10)
      ).reverse()
      let tijdTonen =
        huidigeReverse.find((o) => o.tijdTonen === true) !== undefined
      this.huidige = huidigeReverse.map((team) => {
        team.show = false
        team.plaats = null
        team.plaatsTonen = null
        team.index = null
        return team
      })
      this.tijdelijke = this.huidige.map((o) => ({ ...o }))
      this.nieuwe = this.$addStandData(this.stand.nieuwe.map((o) => ({ ...o })))
      setTimeout(() => {
        this.animateTeam(huidigeReverse).then(() => {
          this.showPlaats = true
          this.$emit('setShowPlaats', tijdTonen)
          this.$store.dispatch('controls/setShow', true)
        })
      }, 2500)
    },
    animateTeam(huidigeReverse) {
      console.log('animateTeam')
      // this.$store.dispatch('audio/play', {
      //   id: 'verschuiven_leaderboard',
      // })
      return new Promise((resolve) => {
        let teamHuidige = huidigeReverse.shift()
        this.animateTeamPuntenErbij(teamHuidige).then(() => {
          if (huidigeReverse.length) {
            this.animateTeam(huidigeReverse).then(() => {
              resolve()
            })
          } else {
            resolve()
          }
        })
      })
    },
    animateTeamPuntenErbij(teamHuidige) {
      console.log('animateTeamPuntenErbij')
      return new Promise((resolve) => {
        const teamEl = this.$refs['team' + teamHuidige.kwis_team_id]
        const teamPuntenEl = teamEl.querySelector('.team__punten')
        const teamNieuwe = this.getTeamFromTeams(this.nieuwe, teamHuidige)
        let interval = setInterval(() => {
          if (teamHuidige.punten < teamNieuwe.punten) {
            teamHuidige.punten = teamHuidige.punten + 1
            teamPuntenEl.innerHTML = teamHuidige.punten
          } else {
            clearInterval(interval)
            setTimeout(() => {
              this.tijdelijke = this.tijdelijke.map((teamTijdelijke) => {
                if (teamTijdelijke.id === teamHuidige.id) {
                  teamTijdelijke.show = true
                  teamTijdelijke.punten = teamNieuwe.punten
                  teamTijdelijke.plaats = teamNieuwe.plaats
                  teamTijdelijke.plaatsTonen = teamNieuwe.plaatsTonen
                  teamTijdelijke.index = teamNieuwe.index
                }
                return teamTijdelijke
              })
              this.huidige = this.huidige.map((team) => {
                if (team.id === teamHuidige.id) {
                  team.show = true
                }
                return team
              })
              let tijdelijke = this.tijdelijke.filter((team) => team.show)
              tijdelijke = this.$addStandData(tijdelijke)
              this.animateTeamsNieuweStand(
                this.$addStandData(this.huidige.filter((team) => team.show)),
                tijdelijke,
                teamHuidige
              ).then(() => {
                resolve()
              })
            }, 1)
          }
        }, 0)
      })
    },
    animateTeamsNieuweStand(huidige, nieuwe, teamHuidige) {
      console.log('animateTeamsNieuweStand')
      setTimeout(() => {
        this.$store.dispatch('audio/play', {
          id: 'verschuiven_leaderboard',
        })
      }, 0)
      return new Promise((resolve) => {
        let tl = gsap.timeline()
        nieuwe = this.$addStandData(nieuwe)
        huidige.forEach((teamHuidige) => {
          const teamEl = this.$refs['team' + teamHuidige.kwis_team_id]
          const teamPlaatsIconEl = teamEl.querySelector('.team__plaats__icon')
          const teamNieuwe = this.getTeamFromTeams(nieuwe, teamHuidige)
          tl.to(
            teamEl,
            {
              duration: 0.4,
              top: this.getTeamTop(teamNieuwe),
              left: this.getTeamLeft(teamNieuwe),
            },
            ''
          )
          teamPlaatsIconEl.innerHTML = teamNieuwe.plaats
          tl.call(
            () => {
              this.huidige = this.tijdelijke.map((o) => ({ ...o }))
            },
            this,
            '>'
          )
          tl.call(
            () => {
              this.animateTeamFocus(teamHuidige).then(() => {
                resolve()
              })
            },
            this,
            '>'
          )
        })
      })
    },
    animateTeamFocus(teamHuidige) {
      console.log('animateTeamFocus')
      return new Promise((resolve) => {
        let teamEl = this.$refs['team' + teamHuidige.kwis_team_id]
        let tl = gsap.timeline()
        tl.to(teamEl, { duration: 1, opacity: 1 }, '>')
        tl.call(
          () => {
            resolve()
          },
          this,
          '>'
        )
      })
    },
    getTeamFromTeams(teams, team) {
      return teams.find((t) => t.id === team.id)
    },
    getTeamTop(t) {
      const top = t.index % this.teamRows
      if (top === 0) {
        return '0px'
      }
      return top * (this.teamHeight + this.teamGutterHeight).toString() + 'px'
    },
    getTeamLeft(t) {
      const left = Math.floor(t.index / this.teamRows)
      if (left === 0) {
        return '0px'
      }
      return (left * (this.teamWidth + this.teamGutterWidth)).toString() + 'px'
    },
    teamClass(t) {
      return t.plaats < 4 ? 'team--podium' : ''
    },
    imageSource(image) {
      if (image) {
        return image
      }
      return 'team-no-image.png'
    },
  },
  watch: {
    programmaId: {
      immediate: true,
      handler() {
        this.$store.dispatch('audio/play', {
          id: 'intro_tussenklassement',
        })
        setTimeout(() => {
          this.animate()
        }, 1000)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.teams {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 80px 100px;
  transition: opacity 400ms;
  border-radius: 28px;

  &__fade {
    &__even {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50px;
      background-color: #ffeb94;
    }

    &__linear {
      position: absolute;
      top: 0;
      right: 50px;
      bottom: 0;
      width: 45px;
      background-image: linear-gradient(
        to left,
        rgba(255, 235, 148, 1),
        rgba(255, 235, 148, 0)
      );
    }
  }

  &__container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &--show-plaats {
    .team__plaats {
      opacity: 1;
    }
  }
}

.team {
  position: absolute;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  color: #333132;
  font-size: 30px;
  line-height: 30px;
  border-radius: 28px;
  background-color: #ffffff;
  font-family: AmsiProCondensed-Black;
  text-transform: uppercase;
  width: 557px;

  &--podium {
    .team__plaats {
      &__icon {
        background-color: #dc0c15;
        color: #ffffff;
      }
    }
  }

  &__plaats {
    margin-left: 23px;
    width: 40px;
    opacity: 0;

    &__icon {
      text-align: center;
      border-radius: 50%;
      background-color: transparent;
      color: #dc0c15;
      width: 40px;
      height: 40px;
      margin-top: 21px;
      padding-top: 7px;
    }
  }

  &__naam {
    flex-grow: 1;
    padding: 28px 23px 24px 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__gelijk {
    padding-top: 24px;
    margin-right: 16px;
  }

  &__punten {
    padding: 28px 0 24px 0;
    margin-right: 130px;
    width: 30px;
    text-align: center;
    color: #50a2a4;
  }

  &__afbeelding {
    position: absolute;
    right: -9px;
    top: -9px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    border-color: #ffffff;
    border-style: solid;
    border-width: 6px;
    background-color: #ffffff;

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
