<template>
  <div class="programma-layout" v-if="kwis">
    <div class="programma-layout__links">
      <div class="programma-layout__links__settings-button">
        <SettingsButton />
      </div>
      <div class="programma-layout__links__logo">
        <img src="/img/logo-hoog.png" />
      </div>
      <div class="programma-layout__links__titel">
        Dielnimmers:
      </div>
      <div class="programma-layout__links__teams">
        <Teams />
      </div>
    </div>
    <div class="programma-layout__rechts">
      <Programma />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Teams from '@/components/kwis/Teams'
import Programma from '@/components/kwis/Programma'
import SettingsButton from "@/components/SettingsButton";

export default {
  props: [],
  components: {
    SettingsButton,
    Teams,
    Programma,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time', 'stand']),
  },
}
</script>

<style lang="scss" scoped>
body {
  background-color: #ffeb94;
}

.programma-layout {
  display: flex;
  height: 100%;

  &__links {
    position: relative;
    width: 650px;
    background-color: #ffcc4e;
    height: 100%;
    padding: 45px 60px 0 120px;

    &__settings-button {
      position: absolute;
      top: 0px;
      left: 541px;
    }

    &__logo {
      img {
        width: 100%;
      }
    }

    &__titel {
      font-size: 26px;
      line-height: 26px;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: #333132;
      text-align: center;
      text-transform: uppercase;
      margin: 52px 0 20px;
    }

    &__teams {
      margin: 30px -10px;
    }

    &__stand {
      margin: 40px 0;
    }
  }

  &__rechts {
    width: 1270px;
    background-color: #ffeb94;
    height: 100%;
    padding: 106px 270px 0 192px;
  }
}
</style>
