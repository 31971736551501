<template>
  <carousel :settings="settings" v-if="slides">
    <slide v-for="(slide, index) in slides" :key="'slide-' + index">
      <div class="teams">
        <Team v-for="team in slide" :key="'team-' + team.id" :team="team" />
      </div>
    </slide>
    <template #addons>
      <pagination />
    </template>
  </carousel>
</template>

<script>
import { mapState } from 'vuex'
import Team from '@/components/kwis/Team.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  components: {
    Team,
    Carousel,
    Slide,
    Pagination,
  },
  computed: {
    ...mapState(['kwis']),
    settings() {
      return {
        itemsToShow: 1,
        transition: 800,
        snapAlign: 'start',
        autoplay: this.kwis.status === 'programma' ? 10000 : 0,
        wrapAround: true,
      }
    },
    slides() {
      let slide = []
      let teams = this.kwis.teams.map((t) => ({ ...t }))
      while (teams.length) {
        slide.push(teams.splice(0, 5))
      }
      return slide
    },
  },
}
</script>

<style lang="scss" scoped>
.teams {
  background-color: #ffcc4e;
  width: 100%;
  padding: 0 10px;
}
</style>
