export default {
  install: (app) => {
    app.config.globalProperties.$dateFormat = (d) => {
      let date = new Date(d)
      return new Intl.DateTimeFormat('nl-NL', {
        dateStyle: 'full',
        timeStyle: 'long',
      }).format(date)
    }
    app.config.globalProperties.$date = (d) => {
      return new Date(d)
    }
    app.config.globalProperties.$timestamp = (d) => {
      return d.getTime() / 1000
    }
    app.config.globalProperties.$addStandData = (teams) => {
      teams.sort(function (a, b) {
        if (a.punten === b.punten) {
          if (a.seconden_antwoorden === b.seconden_antwoorden) {
            return ('' + a.naam).localeCompare(b.naam)
          }
          return a.seconden_antwoorden < b.seconden_antwoorden ? -1 : 1
        }
        return a.punten > b.punten ? -1 : 1
      })
      let plaats = 0
      let punten = null
      let seconden_antwoorden = null
      return teams.map((current, index, all) => {
        let tijdTonen = false
        if (index > 0) {
          if (all[index - 1].punten === current.punten) {
            tijdTonen = true
          }
        }
        if (index + 1 < all.length) {
          if (all[index + 1].punten === current.punten) {
            tijdTonen = true
          }
        }
        current.tijdTonen = tijdTonen
        if (current.punten !== punten) {
          punten = current.punten
          seconden_antwoorden = current.seconden_antwoorden
          plaats++
          current.plaatsTonen = true
        } else {
          if (current.seconden_antwoorden !== seconden_antwoorden) {
            seconden_antwoorden = current.seconden_antwoorden
            plaats++
            current.plaatsTonen = true
          } else {
            current.plaatsTonen = false
          }
        }
        current.plaats = plaats
        current.index = index
        return current
      })
    }
  },
}
