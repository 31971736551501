<template>
  <div v-if="days">{{ days }}</div>
  <div>{{ hours }}:{{ minutes }}:{{ seconds }}</div>
</template>

<script>
export default {
  props: ['secondsBeforeStart'],
  methods: {
    zeroPad(num, places) {
      var zero = places - num.toString().length + 1
      return Array(+(zero > 0 && zero)).join('0') + num
    },
  },
  computed: {
    days() {
      // get total seconds between the times
      //var delta = Math.abs(date_future - date_now) / 1000;
      // calculate (and subtract) whole days
      var delta = this.secondsBeforeStart
      var days = Math.floor(delta / 86400)
      var daysText = days > 1 ? 'dei' : 'dagen'
      return days
    },
    hours() {
      // get total seconds between the times
      //var delta = Math.abs(date_future - date_now) / 1000;
      // calculate (and subtract) whole days
      var delta = this.secondsBeforeStart
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24
      return this.zeroPad(hours, 2)
    },
    minutes() {
      // get total seconds between the times
      //var delta = Math.abs(date_future - date_now) / 1000;
      // calculate (and subtract) whole days
      var delta = this.secondsBeforeStart
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60
      return this.zeroPad(minutes, 2)
    },
    seconds() {
      // get total seconds between the times
      //var delta = Math.abs(date_future - date_now) / 1000;
      // calculate (and subtract) whole days
      var delta = this.secondsBeforeStart
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60
      // what's left is seconds
      var seconds = delta % 60 // in theory the modulus is not required
      return this.zeroPad(seconds, 2)
    },
  },
}
</script>

<style scoped lang="scss"></style>
