<template> </template>

<script>
import KwisService from '@/services/KwisService'
import router from '@/router'

export default {
  mounted() {
    KwisService.logout()
      .then(() => {
        this.$store.dispatch('kwis/clear')
        this.$store.dispatch('network/setSubscribed', false)
        this.router.push({ name: 'KwismasterLogin' })
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<style scoped lang="scss"></style>
