import TimeService from '@/services/TimeService'

const state = {
  timestamp: null,
  duration: null,
}

const mutations = {
  setTimestamp(state, timestamp) {
    state.timestamp = timestamp
  },
  setDuration(state, duration) {
    state.duration = duration
  },
}

const actions = {
  set({ commit }) {
    return TimeService.get()
      .then((response) => {
        if (response.data.status == 'ok') {
          let timestamp = response.data.time.timestamp
          commit('setTimestamp', timestamp)
          setInterval(() => {
            timestamp++
            commit('setTimestamp', timestamp)
          }, 1000)
          //sync time every minute
          setInterval(() => {
            TimeService.get().then((response) => {
              timestamp = response.data.time.timestamp
            })
          }, 1000 * 60)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

const getters = {
  config: (state) => {
    return state
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
