import { createStore } from 'vuex'
import audio from '@/store/modules/audio.js'
import config from '@/store/modules/config.js'
import controls from '@/store/modules/controls.js'
import kwis from '@/store/modules/kwis.js'
import network from '@/store/modules/network.js'
import notification from '@/store/modules/notification.js'
import stand from '@/store/modules/stand.js'
import time from '@/store/modules/time.js'

export default createStore({
  modules: {
    audio: audio,
    config: config,
    controls: controls,
    kwis: kwis,
    network: network,
    notification: notification,
    stand: stand,
    time: time,
  },
})
