<template>
  <button
    type="button"
    :class="classSettingsButton"
    class="settings-button"
    data-bs-toggle="modal"
    data-bs-target="#settingsModal"
  >
    <i class="fa-solid fa-user-gear"></i>
  </button>
  <div
    class="modal fade"
    id="settingsModal"
    tabindex="-1"
    aria-labelledby="settingsModalLabel"
    aria-hidden="true"
    ref="modal"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="modal-close-button bk-icon bk-shadow"
            data-bs-dismiss="modal"
            aria-label="Slúte"
          >
            <i class="fa-regular fa-xmark"></i>
          </button>
          <Settings />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Settings from './kwis/Settings'
import { Modal } from 'bootstrap'

export default {
  props: ['icon'],
  components: { Settings },
  computed: {
    ...mapState(['kwis']),
    classSettingsButton() {
      return this.icon ? 'bk-icon' : 'custom'
    },
  },
  methods: {
    hideModal() {
      Modal.getInstance(document.querySelector('#settingsModal')).hide()
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.settings-button {
  position: relative;
  background-color: #ffeb94;
  color: #333132;

  &.custom {
    width: 54px;
    height: 62px;
    border-radius: 0 0 9px 9px;
    font-size: 28px;
    line-height: 28px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
</style>
