import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + '/api/time',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

export default {
  get() {
    return apiClient.get('')
  },
}
